import React from 'react';
import './App.css';

class App extends React.Component {
  state = {
    title: '',
    min_person: 0,
    max_person: 0,
    description: '',
    variants_1: '',
    variants_2: '',
    variants_3: '',
    variants_4: '',
    variants_5: '',
    variants_6: '',
    variants_7: '',
    intensity: 0,
    material_count: 0,
    duration: 0,
    material_1: '',
    material_2: '',
    material_3: '',
    material_4: '',
    material_5: '',
    material_6: '',
    material_7: '',
    category: '',
    result: ''
  }

  submitButtonClicked() {
    var game = {
      name: this.state.title,
      min_people: this.state.min_person,
      max_people: this.state.max_person,
      description: this.state.description,
      variants: [
      ],
      intensity: this.state.intensity,
      material_count: this.state.material_count,
      duration: this.state.duration,
      material_usage: [],
      category: this.state.category,
      images: []
    }
    if(this.state.variants_1) game.variants.push(this.state.variants_1);
    if(this.state.variants_2) game.variants.push(this.state.variants_2);
    if(this.state.variants_3) game.variants.push(this.state.variants_3);
    if(this.state.variants_4) game.variants.push(this.state.variants_4);
    if(this.state.variants_5) game.variants.push(this.state.variants_5);
    if(this.state.variants_6) game.variants.push(this.state.variants_6);
    if(this.state.variants_7) game.variants.push(this.state.variants_7);

    if(this.state.material_1 && this.state.material_1 != "Nichts!") game.material_usage.push(this.state.material_1);
    if(this.state.material_2) game.material_usage.push(this.state.material_2);
    if(this.state.material_3) game.material_usage.push(this.state.material_3);
    if(this.state.material_4) game.material_usage.push(this.state.material_4);
    if(this.state.material_5) game.material_usage.push(this.state.material_5);
    if(this.state.material_6) game.material_usage.push(this.state.material_6);
    if(this.state.material_7) game.material_usage.push(this.state.material_7);

    var game_string = String(JSON.stringify(game)).replace(/&/g, 'und');

    window.location.href = `mailto:robin.aegi@bluewin.ch?subject=BurnerGameJSON&body=${game_string}`;
  }

  render() {
      return (
        <div className="App">
          <div className="input">
            <h1>Generate Burner Game for TVE</h1>
          </div>
          <div className="input">
            <p>Kategorie:</p>
            <input type="text" name="category" onChange={input => this.setState({ category: input.target.value })} />
          </div>
          <div className="input">
            <p>Titel:</p>
            <input type="text" name="title" onChange={input => this.setState({ title: input.target.value })} />
          </div><br/>
          <div className="input">
            <p>Minimum Personen:</p>
            <input type="number" name="min_person" onChange={input => this.setState({ min_person: input.target.value })} />
          </div>
          <div className="input">
            <p>Maximum Personen:</p>
            <input type="number" name="max_person" onChange={input => this.setState({ max_person: input.target.value })} />
          </div><br/>
          <div className="input">
            <p className="description">Beschreibung:</p><br />
            <textarea name="description"  onChange={input => this.setState({ description: input.target.value })}></textarea>
          </div><br/>
          <div className="input">
            <p>Variante 1:</p>
            <input type="text" name="variant_1" placeholder="Falls keine: alle leer lassen!" onChange={input => this.setState({ variants_1: input.target.value })} />
          </div>
          <div className="input">
            <p>Variante 2:</p>
            <input type="text" name="variant_2" onChange={input => this.setState({ variants_2: input.target.value })} />
          </div>
          <div className="input">
            <p>Variante 3:</p>
            <input type="text" name="variant_3" onChange={input => this.setState({ variants_3: input.target.value })} />
          </div>
          <div className="input">
            <p>Variante 4:</p>
            <input type="text" name="variant_4" onChange={input => this.setState({ variants_4: input.target.value })} />
          </div>
          <div className="input">
            <p>Variante 5:</p>
            <input type="text" name="variant_5" onChange={input => this.setState({ variants_5: input.target.value })} />
          </div>
          <div className="input">
            <p>Variante 6:</p>
            <input type="text" name="variant_6" onChange={input => this.setState({ variants_6: input.target.value })} />
          </div>
          <div className="input">
            <p>Variante 7:</p>
            <input type="text" name="variant_7" onChange={input => this.setState({ variants_7: input.target.value })} />
          </div><br/>
          <div className="input">
            <p>Intensität:</p>
            <input type="number" max="3" min="1" onChange={input => this.setState({ intensity: input.target.value })} />
          </div>
          <div className="input">
            <p>Materialaufwand:</p>
            <input type="number" max="3" min="1" onChange={input => this.setState({ material_count: input.target.value })} />
          </div>
          <div className="input">
            <p>Zeitaufwand:</p>
            <input type="number" max="3" min="1" onChange={input => this.setState({ duration: input.target.value })} />
          </div><br/>
          <div className="input">
            <p>Es braucht 1:</p>
            <input type="text" placeholder="Für nichts: alle leer lassen!" name="material_usage_1" onChange={input => this.setState({ material_1: input.target.value })} />
          </div>
          <div className="input">
            <p>Es braucht 2:</p>
            <input type="text" name="material_usage_2" onChange={input => this.setState({ material_2: input.target.value })} />
          </div>
          <div className="input">
            <p>Es braucht 3:</p>
            <input type="text" name="material_usage_3" onChange={input => this.setState({ material_3: input.target.value })} />
          </div>
          <div className="input">
            <p>Es braucht 4:</p>
            <input type="text" name="material_usage_4" onChange={input => this.setState({ material_4: input.target.value })} />
          </div>
          <div className="input">
            <p>Es braucht 5:</p>
            <input type="text" name="material_usage_5" onChange={input => this.setState({ material_5: input.target.value })} />
          </div>
          <div className="input">
            <p>Es braucht 6:</p>
            <input type="text" name="material_usage_6" onChange={input => this.setState({ material_6: input.target.value })} />
          </div>
          <div className="input">
            <p>Es braucht 7:</p>
            <input type="text" name="material_usage_7" onChange={input => this.setState({ material_7: input.target.value })} />
          </div><br/><br/>
          <div className="input">
            <input type="submit" value="Mail erstellen" onClick={this.submitButtonClicked.bind(this)} />
          </div>
          <p>{this.state.error}</p>
        </div>
    );
  }
}

export default App;
